<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner class="loader"></mat-spinner>
</div>

<div class="content-box" *ngIf="!loading">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper shifts ngx-datatable-template">
        <h6 class="element-header">
          {{ 'Events&Tickets.Orders.Title' | translate }}
        </h6>
        <div class="row">
          <div class="col-md-8">
            <div class="element-box mat-wrapper">
              <div class="row">
                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">{{ 'Events&Tickets.Orders.Events' | translate }}</label>
                      <ng-select
                        [searchable]="false"
                        [clearable]="true"
                        [(ngModel)]="filter.eventId"
                        placeholder="{{ 'Events&Tickets.Orders.Events' | translate }}"
                        [class.filtered]="filter.eventId"
                      >
                        <ng-option [value]="event.id" *ngFor="let event of allEvents">{{ event.name }}</ng-option>
                      </ng-select>
                    </div>
                  </section>
                </div>

                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">{{ 'Events&Tickets.Orders.EventDate' | translate }}</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt" (click)="eventDatePicker.open()"></i>
                          </div>
                        </div>

                        <mat-datepicker #eventDatePicker (closed)="eventDateInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #eventDateInput
                          placeholder=""
                          [(ngModel)]="filter.eventDate"
                          type="text"
                          name="eventDate"
                          [matDatepicker]="eventDatePicker"
                          (focus)="eventDatePicker.open()"
                        />
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-times" (click)="clearDate($event)"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">&nbsp;</label>
                      <br />
                      <mat-slide-toggle name="showAll" [(ngModel)]="filter.showAll">
                        {{ 'Events&Tickets.Orders.ShowAll' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </section>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group row_">
                    <form class="example-form">
                      <label style="margin-bottom: -4px; display: block;">{{
                        'Events&Tickets.Orders.Search' | translate
                      }}</label>
                      <mat-form-field class="example-full-width" style="width: 100%;">
                        <input
                          type="text"
                          matInput
                          [formControl]="myControl"
                          [(ngModel)]="filterBy"
                          placeholder="{{ 'Events&Tickets.Orders.SearchFor' | translate }}"
                          style="width: auto;"
                          (keyup)="filterOrdersBy('client', $event.target.value)"
                        />
                      </mat-form-field>
                    </form>
                  </div>
                </div>

                <ng-template [ngIf]="!filter.showAll">
                  <div class="col-md-4">
                    <section>
                      <div class="form-group">
                        <label for="">{{ 'Events&Tickets.Orders.FromDate' | translate }}</label>
                        <div class="input-group">
                          <div class="input-group-prepend" (click)="dateFromPicker.open()">
                            <div class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </div>
                          </div>
                          <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                          <input
                            class="form-control"
                            #dateFromInput
                            placeholder=""
                            [(ngModel)]="dates.from"
                            type="text"
                            name="date"
                            [matDatepicker]="dateFromPicker"
                            (focus)="dateFromPicker.open()"
                          />
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="col-md-4">
                    <section>
                      <div class="form-group">
                        <label for="">{{ 'Events&Tickets.Orders.EndDate' | translate }}</label>
                        <div class="input-group">
                          <div class="input-group-prepend" (click)="dateFromPicker.open()">
                            <div class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </div>
                          </div>
                          <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                          <input
                            class="form-control"
                            #dateToInput
                            placeholder=""
                            [(ngModel)]="dates.to"
                            type="text"
                            name="date"
                            [matDatepicker]="dateToPicker"
                            (focus)="dateToPicker.open()"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                </ng-template>
                <!-- <div class="col-md-3">
                      <section>
                        <div>
                          <label for="">Betrag Filter</label>
                          <div>
                            <select
                              class="form-control"
                              [(ngModel)]="amountFilter"
                              (change)="filterAmountStatus($event.target.value)"
                            >
                              <option value="0">Alle</option>
                              <option value="1">Paid</option>
                              <option value="2">Free</option>
                            </select>
                          </div>
                        </div>
                      </section>
                    </div> -->

                <div class="col-md-10">
                  <section>
                    <div class="form-group actions">
                      <label for="">{{ 'Events&Tickets.Common.Actions' | translate }}</label
                      ><br />
                      <button class="btn btn-light" (click)="setDates('today')">
                        {{ 'Events&Tickets.Orders.Today' | translate }}
                      </button>
                      <button class="btn btn-light" (click)="setDates('yesterday')">
                        {{ 'Events&Tickets.Orders.Yesterday' | translate }}
                      </button>
                      <button class="btn btn-light" (click)="setDates('lastSeven')">
                        {{ 'Events&Tickets.Orders.Last7Days' | translate }}
                      </button>
                      <button class="btn btn-light" (click)="setDates('thisWeek')">
                        {{ 'Events&Tickets.Orders.CurrentWeek' | translate }}
                      </button>
                      <button class="btn btn-light" (click)="setDates('lastWeek')">
                        {{ 'Events&Tickets.Orders.LastWeek' | translate }}
                      </button>
                      <button class="btn btn-light" (click)="setDates('thisMonth')">
                        {{ 'Events&Tickets.Orders.CurrentMonth' | translate }}
                      </button>
                      <button class="btn btn-light" (click)="setDates('lastMonth')">
                        {{ 'Events&Tickets.Orders.LastMonth' | translate }}
                      </button>
                    </div>
                  </section>
                </div>

                <div class="col-md-2 col-sm-12 submit-btn-div">
                  <label for="">&nbsp;</label>
                  <br />
                  <button class="btn btn-primary btn-lg" (click)="getTransactions(false)">
                    {{ 'Common.Submit' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4" *ngIf="ticketOrders.length">
            <a class="element-box el-tablo" style="padding: 1.65rem 1rem 1.65rem;" href="javascript:return;">
              <div class="row">
                <div class="col-sm-8">
                  <div class="label">
                    {{ 'Events&Tickets.Orders.Sales' | translate }} {{ 'Events&Tickets.Orders.Gross' | translate }}
                  </div>
                  <div class="value">
                    {{ filterStats?.orders.total | currency: 'EUR' }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="label">
                    {{ 'Events&Tickets.Orders.No' | translate }}
                  </div>
                  <div class="value text-right">
                    {{ filterStats.orders.count }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <div class="label">
                    {{ 'Events&Tickets.Orders.Cancellations' | translate }}
                    {{ 'Events&Tickets.Orders.Gross' | translate }}
                  </div>
                  <div class="value">
                    {{ filterStats?.cancelations.total | currency: 'EUR' }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="label">
                    {{ 'Events&Tickets.Orders.Cancellations' | translate }}
                  </div>
                  <div class="value text-right">
                    {{ filterStats?.cancelations.count }}
                  </div>
                </div>
              </div>
            </a>
            <!-- <a class="" style="padding: 1.65rem 1rem 1.65rem;" href="javascript:return;">
              <div class="row">
                <div class="col-sm-4">
                  <button class="btn btn-primary btn-block" (click)="downloadTicketList(pdf)">
                   PDF
                  </button>
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-primary btn-block" (click)="downloadTicketList(csv)">
                   CSV
                  </button>
                </div>
              </div>
            </a> -->
          </div>
        </div>

        <div class="element-box datatable-res-transactions">
          <ngx-datatable
            #myTable
            class="material striped expandable"
            [rows]="ticketOrders"
            [columnMode]="'force'"
            [limit]="tableLimit"
            [sorts]="[{ prop: 'id', dir: 'desc' }]"
            [scrollbarH]="false"
            [rowHeight]="'100%'"
            headerHeight="50"
            footerHeight="50"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-row-detail [rowHeight]="'100%'" #myDetailRow>
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="ticket-list">
                  <ul *ngIf="row.tickets && row.tickets.length">
                    <li *ngFor="let ticket of row.tickets">
                      <label>{{ 'Events&Tickets.Common.TicketName' | translate }} - </label>
                      <span class="value">&nbsp;{{ ticket.ticket.name }}</span>
                      <span class="value" *ngIf="ticket.subCategory && ticket.subCategory.name">
                        ({{ ticket.subCategory.name }})</span
                      >
                      <span *ngIf="ticket.code" class="pdf-files" (click)="prepareTicketPdf(ticket.code)">
                        <i class="fas fa-file-pdf"></i>
                      </span>
                    </li>
                  </ul>
                  <ul *ngIf="!row.tickets || !row.tickets.length">
                    <li>
                      <label>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</label>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column
              [width]="30"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false"
            >
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a
                  href="javascript:void(0)"
                  [class.datatable-icon-down]="expanded"
                  title="Expand/Collapse Row"
                  (click)="toggleExpandRow(row)"
                >
                  <i class="fas fa-chevron-right"></i>
                </a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="ID" prop="id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{ row.id }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="true"
              name="{{ 'Events&Tickets.Common.Date' | translate }}"
              prop="orderDate"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="date-label">{{ row.createdAt | date: 'dd.MM.yyyy' }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="true"
              name="{{ 'Events&Tickets.Common.EventName' | translate }}"
              prop="eventName"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row && row.eventName">{{ row.eventName }} </span>
                <span *ngIf="row && row.eventDate">({{ row.eventDate }}) </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="true"
              name="{{ 'Events&Tickets.Orders.PaymentMethod' | translate }}"
              prop="paymentProvider"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="row.paymentMethod !== 'free' && row.paymentMethod !== 'klarna'">
                  <img
                    style="max-height: 28px"
                    src="https://www.gastroguide.de/imgs/business/payment/{{ row.paymentMethod }}.png"
                  />
                </div>
                <div *ngIf="row.paymentMethod !== 'free' && row.paymentMethod == 'klarna'">
                  <img
                    style="max-height: 28px"
                    src="https://www.gastroguide.de/imgs/business/payment/sofortueberweisung_small.png"
                  />
                </div>
                <span *ngIf="row.paymentMethod === 'free'">Free</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="true"
              name="{{ 'Events&Tickets.Common.Amount' | translate }}"
              prop="total"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="price-label" style="white-space: nowrap;">{{ row.subtotal | currency: 'EUR' }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.Orders.Buyers' | translate }}" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>
                  <a href="mailto:{{ row.email }}">{{ row.firstName }} {{ row.lastName }}</a>
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="Status" prop="status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.status == 'confirmed'" class="text-success">Fertig</span>
                <span *ngIf="row.status == 'canceled'" class="text-danger">Storniert</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="" prop="details">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i (click)="showAddress($event, row)" ngbTooltip="Details" class="fa fa-info-circle details-icon"></i>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="false" name="Aktionen" prop="email">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="action-btn">
                  <button (click)="sendTicketPdf(row)" class="btn btn-small btn-primary" ngbTooltip="Send ticket PDF">
                    <i class="fas fa-envelope-open"></i>
                  </button>
                  <button (click)="sendReceipt(row)" class="btn btn-small btn-primary" ngbTooltip="Quittung senden">
                    <i class="fas fa-envelope-open-text"></i>
                  </button>
                  <button class="btn btn-light view-btn" (click)="moveTickets(row)">
                    <i class="fas fa-arrows-alt"></i>
                  </button>
                  <button
                    class="btn btn-light"
                    *ngIf="row.status !== 'canceled'"
                    (click)="cancelTicket(row.id)"
                    style="margin-right: 5px"
                    ngbTooltip="Stornieren"
                  >
                    <i class="fas fa-strikethrough"></i>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

          <div class="grid">
            <mat-paginator
              [length]="ticketOrders.length"
              pageSize="{{ pageSize }}"
              (page)="getPaginatorData($event)"
              [pageSizeOptions]="[10, 20, 50]"
              showFirstLastButtons="false"
            >
            </mat-paginator>
            <div class="row">
              <ng-container *ngFor="let row of ticketOrdersMobile; let i = index">
                <!-- <div
                  class="col-sm-6 col-md-6"
                  *ngIf="i < table.offset * tableLimit + tableLimit && i >= table.offset * tableLimit"
                > -->
                <div class="card" id="card-{{ row.id }}">
                  <div class="card-body">
                    <h5 class="card-title">
                      <span class="price-label_">{{ row.subtotal | currency: 'EUR' }}</span>
                      <span class="badge float-right" style="font-weight: normal;">
                        <div *ngIf="row.paymentMethod !== 'klarna'">
                          <img
                            style="max-height: 28px"
                            src="https://www.gastroguide.de/imgs/business/payment/{{ row.paymentMethod }}.png"
                          />
                        </div>
                        <div *ngIf="row.paymentMethod == 'klarna'">
                          <img
                            style="max-height: 28px"
                            src="https://www.gastroguide.de/imgs/business/payment/sofortueberweisung_small.png"
                          />
                        </div>
                      </span>
                      <br />
                      <small
                        >{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                        {{ row.createdAt | amDateFormat: 'HH:mm' }}</small
                      >
                      <br />
                      <small>
                        <span *ngIf="row.status == 'confirmed'" class="text-success">Fertig</span>
                        <span *ngIf="row.status == 'canceled'" class="text-danger">Storniert</span>
                      </small>
                    </h5>
                    <p class="card-text" style="font-size: 13px;">
                      <span>
                        <a href="mailto:{{ row.email }}">{{ row.firstName }} {{ row.lastName }}</a>
                        <ng-container
                          >&nbsp;
                          <i
                            (click)="showAddress($event, row)"
                            ngbTooltip="Details"
                            class="fa fa-info-circle details-icon"
                          ></i>
                        </ng-container>
                        <span class="action-btn" style="float:right">
                          <button
                            (click)="sendTicketPdf(row)"
                            class="btn btn-small btn-primary"
                            ngbTooltip="Send ticket PDF"
                          >
                            <i class="fas fa-envelope-open"></i>
                          </button>
                          <button
                            (click)="sendReceipt(row)"
                            class="btn btn-small btn-primary"
                            ngbTooltip="Quittung senden"
                          >
                            <i class="fas fa-envelope-open-text"></i>
                          </button>
                          <button class="btn btn-light view-btn" (click)="moveTickets(row)">
                            <i class="fas fa-arrows-alt"></i>
                          </button>
                          <button
                            class="btn btn-light"
                            *ngIf="row.status !== 'canceled'"
                            (click)="cancelTicket(row.id)"
                            style="margin-right: 5px"
                            ngbTooltip="Stornieren"
                          >
                            <i class="fas fa-strikethrough"></i>
                          </button>
                        </span>
                        <br />
                        <br />
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <span *ngIf="row && row.eventName">{{ row.eventName }} </span>
                              <span *ngIf="row && row.eventDate">({{ row.eventDate }}) </span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="ticket-list">
                            <ul *ngIf="row.tickets && row.tickets.length">
                              <li *ngFor="let ticket of row.tickets">
                                <div class="ticket-name">
                                  <label>Ticket Name</label>
                                  <span class="value">{{ ticket.ticket.name }}</span>
                                  <span class="value" *ngIf="ticket.subCategory && ticket.subCategory.name">
                                    ({{ ticket.subCategory.name }})</span
                                  >
                                  <span *ngIf="ticket.code" class="pdf-files" (click)="prepareTicketPdf(ticket.code)">
                                    <i class="fas fa-file-pdf"></i>
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </mat-expansion-panel> </span
                      ><br />
                    </p>
                  </div>
                </div>
                <!-- </div> -->
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
